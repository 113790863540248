/*
  mgupta: 2020/05/17
  Adapted from older sources. Basically, to take INSPINIA and give it a fixed sidebar
 */

$sidebar-width: 220px;

/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

/** mgupta: Override/Introduce new styles **/
.pace-done .navbar-fixed-side {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

body.fixed-sidebar.mini-navbar .navbar-fixed-side {
  width: 70px;
}

body.fixed-nav.mini-navbar.mini-navbar #page-wrapper {
  margin: 0 0 0 70px;
}

body.fixed-nav.body-small #page-wrapper {
  margin: 0 0 0 0;
}

body.fixed-nav.fixed-nav-basic #wrapper .navbar-fixed-side {
  margin-top: 0;
}

body .navbar-fixed-side {
  position: fixed;
  width: $sidebar-width;
  z-index: 2001;
  height: 100%;
}

body.mini-navbar .navbar-fixed-side {
  width: 70px;
}

body.fixed-nav.fixed-nav-basic.mini-navbar.body-small .navbar-fixed-top {
  left: 70px;
}

@media (max-width: 768px) {

  #page-wrapper {
    position: inherit;
    margin: 0 0 0 0;
    min-height: 100vh;
  }

  .body-small .navbar-fixed-side {
    display: none;
    z-index: 2001;
    position: fixed;
    width: 70px;
  }

  .body-small.mini-navbar .navbar-fixed-side {
    display: block;
  }

}

/* We want extra large buttons */
.minimalize-styl-2 {
  padding: 10px 16px;

  // Also, we don't want the minimalize button to have color
  &.btn-link:hover,
  &.btn-link:focus,
  &.btn-link:active,
  &.btn-link.active,
  &.open .dropdown-toggle.btn-link {
    color: inherit;
  }
}

body.fixed-nav #page-wrapper {
  margin: 0 0 0 220px;
}

/* We want a elements to center their contents */
.nav > li > a {
  display: flex;
  align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
}

/* mgupta: [02/28/2019] Not sure why these were added
.nav > li > a {
  padding: 15px;
}

.nav > li > a i {
  margin-right: 15px;
}
*/

/*
 * mgupta: these should have been in bootstrap, part of _float.scss. For some reason,
 * whole file is missing from our setup
 */
.kpr-float-right {
  float: right !important;
}

.kpr-float-left {
  float: left !important;
}

.kpr-float-none {
  float: none !important;
}
