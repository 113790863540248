table.kpr-ajaxtable {
  thead {
    .sorting {
      position: relative;
      &:after {
        position: absolute;
        bottom: 8px;
        right: 8px;
        display: block;
        font-family: 'Font Awesome 5 Pro';
        opacity: 0.5;
        content: "";
      }
      &.asc {
        &:after {
          content: "\f884";
        }
      }
      &.desc {
        &:after {
          content: "\f885";
        }
      }
    }
  }

  tr.clickable-row {
    cursor: pointer;
    user-select: none;

    td {
      text-decoration: underline;
      color: #007bff;
    }
  }
}