.tool-height {
  height: 5em;
}

button.lookahead-calendar {

  height: 100% !important;

  .today {
    font-size: 1.1rem;
    line-height: 1.2;
    font-weight: 400;
  }

  .day {
    font-size: .8rem;
    line-height: 1.2;
    font-weight: 300;
  }

  .date {
    font-size: 1.4rem;
    line-height: 1.3;
    font-weight: 400;
  }

  .month {
    font-size: .8rem;
    line-height: 1.2;
    font-weight: 300;
  }
}


.kpr-booking-time-picker {
  &.kpr-btn-container {
    overflow: none;
  }
}