.sorse-icon {
  background-image: url("../img/brand/sorse-badge-red.svg");
  background-position: center;
  background-size: cover;

  &.size-96 {
    height: 96px;
    width: 96px;
  }

  &.size-72 {
    height: 72px;
    width: 72px;
  }

  &.size-60 {
    height: 72px;
    width: 72px;
  }

  &.size-48 {
    height: 48px;
    width: 48px;
  }
}