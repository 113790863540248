// Vendors
@import "vendor";

// Application Styles
@import "bootstrap/sass/color";
@import "shared/components/flex/sass/flex";

// Components
@import "shared/components/ajax-table/sass/ajax-table";
@import "shared/components/slideup-drawer/sass/sliding-stuff";
@import "shared/components/modal/sass/modal";
@import "shared/components/flipping-card/sass/flipping-card";
@import "shared/components/hot-text/sass/hot-text";
@import "vendor/inspinia_admin_theme/sass/frame";
//@import "vue-select/src/scss/vue-select.scss";

// Module styles
@import "reservation/sass/layout";
@import "reservation/sass/calendar";
@import "reservation/sass/tables";
@import "reservation/sass/timeline";
@import "reservation/sass/floor";

// Brand Styles, will be different for each client, but will be merged with app.scss
@import "../brand/sass/brand";

// mgupta: Bootstrap 3, made font-sze change to 10px from 16px, we
// got to change it back to 16px, coz that's what inspinia likes

html {
  font-size: 16px;
}

.has-navbar-fixed-top {
  padding-top: 50px;
}

.nav {
  .menu-item {
    .icon {
      float: left;
      padding-right: 10px;
    }
    .item {
    }
  }
}

.top-navigation {
  .wrapper {
    &.wrapper-content {
      padding: 20px;
    }
  }
}

.content-relative {
  position: relative;
}

.content {
}

.hide-overflow {
  overflow: hidden;
}

.float-e-margins .btn {
  margin-bottom: 0px;
}

// mgupta: this comes from bootstrap, but inspinia seems to have done something as this
// doesn't show through without the !important
.form-control-lg {
  font-size: 1.25rem !important;
}