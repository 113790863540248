@mixin display-flex  {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-direction-horizontal {
  -webkit-box-orient: horizontal !important;
  -moz-box-orient: horizontal !important;
  -ms-box-orient: horizontal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

@mixin flex-direction-vertical {
  -webkit-box-orient: vertical !important;
  -moz-box-orient: vertical !important;
  -ms-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

@mixin pack-start {
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  -ms-box-pack: start;
  justify-content: flex-start;
}

@mixin pack-justify {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -ms-box-pack: justify;
  justify-content: space-around;
}

@mixin pack-center {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin pack-end {
  -webkit-box-pack: flex-end;
  -moz-box-pack: flex-end;
  -ms-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

@mixin align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -moz-box-align: stretch;
  -ms-box-align: stretch;
  align-items: stretch;
}

@mixin align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-box-align: center;
  -ms-box-align: center;
  align-items: center;
}

@mixin align-right {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  -moz-box-align: flex-end;
  -ms-box-align: flex-end;
  align-items: flex-end;
}

@mixin wrap-none {
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}

@mixin wrap-wrap {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

@mixin wrap-reverse {
  flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
}

@mixin flex($grow, $shrink, $basis) {
  -webkit-box-flex: $grow $shrink $basis;
  -moz-box-flex: $grow $shrink $basis;
  -webkit-flex: $grow $shrink $basis;
  -ms-box-flex: $grow $shrink $basis;
  -ms-flex: $grow $shrink $basis;
  flex: $grow $shrink $basis;
}

.flex-container {

  @include display-flex();

  &.pack-start {
    @include pack-start();
  }

  &.pack-justify {
    @include pack-justify();
  }

  &.pack-center {
    @include pack-center();
  }

  &.wrap-none {
    @include wrap-none();
  }

  &.wrap-wrap {
    @include wrap-wrap();
  }

  &.wrap-reverse {
    @include wrap-reverse();
  }

  .flex-item {
    @include display-flex();

    &.flex-item-fill {
      @include flex(1, 0, 0);
    }
  }

  &.align-stretch {
    @include align-stretch();
  }

  &.align-center {
    @include align-center();
  }

  &.align-right {
    @include align-right();
  }

  &.horizontal {
    @include flex-direction-horizontal();
  }

  &.vertical {
    @include flex-direction-vertical();
  }
}