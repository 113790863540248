.kpr-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.kpr-modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  .kpr-modal-container {
    width: 600px;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .kpr-modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
  }

  .kpr-modal-body {
    padding: 20px 15px;
  }

  .kpr-modal-footer {
    padding: 20px 15px 0 15px;
    border-top: 1px solid #e5e5e5;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.kpr-modal-enter {
  opacity: 0;
}

.kpr-modal-leave-active {
  opacity: 0;
}

.kpr-modal-enter .kpr-modal-container {
  -webkit-transform: scale(.8);
  transform: scale(.8);
}

.kpr-modal-leave-active .kpr-modal-container {
  -webkit-transform: scale(.8);
  transform: scale(.8);
}