//
// Styles for the visitors section of site
//
.landing-page {

  /*
  ** The mast heads
  */
  .header-back {
    &.one {
      background: url('../img/brand/jamuns1.jpg') 50% 0 no-repeat;
    }
    &.two {
      background: url('../img/brand/locations.jpg') 50% 0 no-repeat;
    }
    &.three {
      background: url('../img/brand/inside.jpg') 50% 0 no-repeat;;
    }
  }

  /*
  *   THE NAVBAR STYLES
  */
  .navbar-default .navbar-sorse {
    display: block;
    height: 52px;
    width: 52px;
    margin: 5px 20px;
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar.navbar-scroll .navbar-sorse {
    position: absolute;
    height: 62px;
    width: 62px;
    margin: 9px 20px;
  }

  /*
  **  The full view port carousel
  */
  .carousel.full-viewport .carousel-inner .carousel-caption {
    position: absolute;
    top: auto;
    left: 0;
    right: auto;
    bottom: 30px;
    padding: 15px;
    text-align: left;
    width: auto;
    height: auto;
  }
  @media screen and (min-width: 768px) {
    .carousel.full-viewport .carousel-inner .carousel-caption {
      bottom: 0;
      padding: 30px;
    }
  }

  .carousel-caption h1 {
    color: #ffffff;
    font-size: 2rem;
    @media screen and (min-width: 768px) { font-size: 4rem; }
  }

  /*
  *   THE OPENING BADGE STYLES
  */
  .badge-container {
    position: relative;
    width: 100%;
    top: 50%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
  }

  .opening-badge {
    display: inline-block;
    width: 50vw;
    height: 50vw;
    margin: 0 auto;
    padding: 0;
  }

  .carousel-overlay-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vw;
    text-align: right;
    max-width: 80%;
    @media screen and (min-width: 768px){
      max-width: 50%;
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: auto auto;
    padding: 0;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .carousel-badge {
    display: inline-block;
    width: 100vw;
    height: 100vh;
    @media screen and (min-width: 768px){
      width: 80vw;
      height: 80vh;
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
    margin: 0 auto;
    padding: 0;
  }

  .item:nth-of-type(2) .carousel-overlay-image {
    background-image: url('../img/badges/prices-slashed.png');
  }

  .item:nth-of-type(3) .carousel-overlay-image {
    background-image: url('../img/badges/now-delivering.png');
  }
  .item:nth-of-type(4) .carousel-overlay-image {
    background-image: url('../img/badges/catering.png');
  }

  //.item:nth-of-type(1) .carousel-badge {
  //},
  //.item:nth-of-type(2) .carousel-badge {
  //  background-image: url('../img/badges/prices-slashed.png');
  //}
  //.item:nth-of-type(3) .carousel-badge {
  //  background-image: url('../img/badges/now-delivering.png');
  //}
  //.item:nth-of-type(4) .carousel-badge{
  //  background-image: url('../img/badges/catering.png');
  //}

  .moving-on {
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 768px){
      width: 80vw;
    }
  }

  /*
  *   THE SECTION DESCRIPTION STYLES
  */
  .description-content {
    padding-top: 40px;
    padding-bottom: 40px;

    .description-text {
      display: inline-block;
      vertical-align: middle;
      text-align: center;

      h1 {
        padding: 0.5em;
        border: 4px solid $brand-color;
        color: $brand-color;
        font-weight: 300;
        width: 296px;
        display: inline-block;
      }

      p {
        margin-top: 50px;
        margin-bottom: 25px;
        line-height: 1.6em;
      }
    }

    @media screen and (min-width: 1100px) {
      .description-text {
        margin-top: 95px;
      }
    }

    @media screen and (min-width: 768px) {
      .description-text {
        margin-top: 40px;
      }
    }

    @media screen and (max-width: 767px) {
      .description-text {
        margin-top: 0px;
      }
    }
  }

  /*
  *   OUR STORY IMAGES
  */
  .story-images-container {

    .story-images {
      display: inline-block;
      list-style-image: none;
      list-style-type: none;
      margin-right: auto;
      margin-left: auto;
      max-width: 495px;
      padding: 0;
      height: 495px;

      li {
        width: 232px;
        height: 234px;
        margin: 5px;
        background-size: cover;
        display: inline-block;
      }

      li:nth-of-type(1) {
        background: url('../img/brand/bana-flower1.jpg') no-repeat center center;
      }

      li:nth-of-type(2) {
        background: url('../img/brand/boiled-potatoes.jpg') no-repeat center center;
      }

      li:nth-of-type(3) {
        background: url('../img/brand/potol1.jpg') no-repeat center center;
      }

      li:nth-of-type(4) {
        background: url('../img/brand/jackfruit1.jpg') no-repeat center center;
      }
    }
  }

  .align-center {
    text-align: center;
    text-align: -webkit-center;
  }

  /*
  *   THE BACKGROUND IMAGE SECTION
  */
  .background-content {
    position: relative;
    height: 350px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: padding-box;
    background-position: center 0%;
    padding: 0;

    &:nth-of-type(2) {
      background-image: url('../img/brand/sorse-thali-bg.jpg');
    }
    &:nth-of-type(4) {
      background-image: url('../img/brand/crab.jpg');
    }
    &:nth-of-type(6) {
      margin: 0px;
      background-image: url('../img/brand/grandmas-jumbo-box.jpg');
    }

    h1 {
      font-size: 5em;
      color: $brand-color;
      position: relative;
      font-weight: 900;
      display: block;
      text-align: center;
      width: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

      span {
        margin: 0;
        border-bottom-width: 5px;
        border-bottom-style: solid;
        font-weight: 100;
      }
    }
  }

  @media screen and (min-width:1100px){
    .background-content,
    .menu-content,
    .opening-container {
      background-attachment: fixed;
    }
  }

  @media screen and (min-width: 660px){
    .background-content {
      height: 570px;
    }
  }

  /*
  *   OUR DISHES
  */
  .spice-image {
    background-image: url(../img/brand/FiveSpices.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 494px;
    width: 500px;
  }

  /*
  *   OUR DISHES
  */
  .dishes-images-container {
    padding: 0;
    margin: 0;

    .dishes-images {
      width: 500px;
      margin: 0;
      padding: 0;
      height: 495px;
      list-style-image: none;
      list-style-type: none;
      vertical-align: middle;

      li {
        width: 238px;
        height: 500px;
        margin: 5px;
        padding: 0px;
        display: inline-block;

        &:nth-of-type(1) {
          background: url('../img/brand/chop.jpg') no-repeat center center;
        }

        &:nth-of-type(2) {
          background: url('../img/brand/raj-bhog.jpg') no-repeat center center;
        }
      }
    }
  }

  /*
  *   THE COMMENTS SECTION
  */
  section.comments {
    padding-bottom: 0;
  }

  .bubble {
    background-color: #f4f4f4;

    &::after {
      border-color: #f4f4f4 transparent;
    }
  }

  .facebook-iframe {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  // Zomato frame
  #res_widget {
    margin-left: auto;
    margin-right: auto;
  }

  .kpr-float-bottom-right {
    z-index: 99;
    position: fixed;
    right: 20px;
    bottom: 20px;
  }
}

.pricing-plan li {
  color: #676a6c !important;
}

.img-logo {
  max-height:64px;
  max-width: 100%;
}

ul.social-icons {
  font-size: 1.5em;
}

.social-icons li {
  list-style-type: none;
  padding: 10px;
  display: inline-block;
}

.fb_iframe_widget_display_block {
  padding: 15px 0;
  display: block !important;
}
