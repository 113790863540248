.full-viewport-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  .img-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.carousel.full-viewport {
  height: 100%;
  .carousel-item {
    height: 100%;
  }

  .carousel-inner {
    height: 100%;

    .carousel-caption {
      position: absolute;
      padding: 15px;
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
      text-align: right;
      max-width: 100%;
      @media screen and (min-width: 576px){ max-width: 80%; }
      @media screen and (min-width: 768px){ max-width: 50%; }
      height: auto;
    }
  }
}

.img-fill {
  width: 100%;
  height: 100%;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;

  &.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
}
