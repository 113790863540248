// Links
// Style anchor elements.

$blue:                            #007bff !default;
$link-color:                      $blue !default;
$link-decoration:                 none !default;
$link-hover-color:                darken($link-color, 15%) !default;
$link-hover-decoration:           underline !default;

.kpr-hot-text {
  text-decoration: $link-decoration;
  cursor: pointer;
  color: $link-color;

  &:hover {
    text-decoration: $link-hover-decoration;
    color: $link-hover-color
  }

  &.kpt-text {

  }
}

