.tr-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.flipping-card {
  perspective: 10;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;

  -webkit-transition: -webkit-transform .5s;
  -moz-transition: -moz-transform .5s;
  -ms-transition: -ms-transform .5s;
  -o-transition: -o-transform .5s;
  transition: transform .5s;

  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);

  &.flip {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .card-face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;

    &.front {
      z-index: 10;
    }

    &.back {
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      -o-transform: rotateY(180deg);
      transform: rotateY(180deg);
      //      padding: 0;
    }
  }
}
