.kpr-table {
  background-color: #ffffff;
  margin: 20px;
  border: 1px solid #e7eaec;
  width: 260px;
  height: 350px;
  border-radius: 10px;

  &.kpr-center-version {
    padding: 0;
    text-align: center;
  }

  .kpr-table-footer {
    text-align: center;
    background-color: #ffffff;
    border-top: 1px solid #e7eaec;
    padding: 15px 20px;
  }
}