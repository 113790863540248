/*
 * Side navigation
 *
 * Scrollspy and affixed enhanced navigation to highlight sections and secondary
 * sections of menu content.
 */

/* By default it's not affixed in mobile views, so undo that */
body.menu-spy-on-me {
  position: relative;
}

.landing-page .menu-page {
  overflow: inherit;
  padding-top: 40px;

  .menu-sticky {
    position: sticky;
    top: 74px;
  }
}

@media (min-width: 768px) {
  .menu-sidebar {
    padding-left: 20px;
  }
}

/* First level of nav */
.menu-sidenav {
  margin-top: 30px;
  margin-bottom: 20px;
  display: block;
}

/* All levels of nav */
.menu-sidebar .nav > li {
  text-align: right;
}

.menu-sidebar .nav > li > a {
  display: block;
  padding: 4px 20px;
  font-size: 13px;
  font-weight: 500;
  color: #767676;
}
.menu-sidebar .nav > li > a:hover,
.menu-sidebar .nav > li > a:focus {
  padding-left: 19px;
  color: $brand-color;
  text-decoration: none;
  background-color: transparent;
  border-right: 1px solid $brand-color;
}
.menu-sidebar .nav > li > a.active,
.menu-sidebar .nav > li > a.active:hover,
.menu-sidebar .nav > li > a.active:focus {
  padding-left: 18px;
  font-weight: bold;
  color: $brand-color;
  background-color: transparent;
  border-right: 2px solid $brand-color;
}

/* Nav: second level (shown on .active) */
.menu-sidebar .nav .nav {
  display: none; /* Hide by default, but at >768px, show it */
  padding-bottom: 10px;
}
.menu-sidebar .nav .nav > li > a {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 30px;
  font-size: 12px;
  font-weight: normal;
}
.menu-sidebar .nav .nav > li > a:hover,
.menu-sidebar .nav .nav > li > a:focus {
  padding-left: 29px;
}
.menu-sidebar .nav .nav > li > a.active,
.menu-sidebar .nav .nav > li > a.active:hover,
.menu-sidebar .nav .nav > li > a.active:focus {
  padding-left: 28px;
  font-weight: 500;
}

/* Back to top (hidden on mobile) */
.back-to-top,
.bs-docs-theme-toggle {
  display: none;
  padding: 4px 20px 10px;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #999;
  text-align: right;
}
.back-to-top:hover,
.bs-docs-theme-toggle:hover {
  color: $brand-color;
  text-decoration: none;
}
.bs-docs-theme-toggle {
  margin-top: 0;
}

@media (min-width: 768px) {
  .back-to-top,
  .bs-docs-theme-toggle {
    display: block;
  }
}

/* Show and affix the side nav when space allows it */
@media (min-width: 992px) {
  .menu-sidebar .nav > .active > ul {
    display: block;
  }
  /* Widen the fixed sidebar */
  .menu-sidebar.affix-top,
  .menu-sidebar.affix,
  .menu-sidebar.affix-bottom {
    width: 213px;
  }
  .menu-sidebar.affix {
    position: fixed; /* Undo the static from mobile first approach */
    top: 90px;
  }
  .menu-sidebar.affix-bottom {
    position: absolute; /* Undo the static from mobile first approach */
  }
  .menu-sidebar.affix-bottom .menu-sidenav,
  .menu-sidebar.affix .menu-sidenav {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  /* Widen the fixed sidebar again */
  .menu-sidebar.affix-top,
  .menu-sidebar.affix-bottom,
  .menu-sidebar.affix {
    width: 263px;
  }
}

.menu-content {
  position: relative;

  .sorse-line {
    width: 60px;
    height: 1px;
    margin: 0 auto 50px;
    border-bottom: 2px solid #ffc621;
  }
}

/* Show and affix the order-now button when space allows it */
a.order-now.affix-top {
  margin-top: 30px;
}

@include media-breakpoint-up(md){
  .order-now.affix {
    position: fixed;
    top: 90px;
  }

  .order-now.affix-bottom {
    position: absolute;
  }
}

/* Show and affix the order-now-floating button when space allows it */
.floating-order {
  text-align: right;
}

a.order-now-floating.affix {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

a.order-now-floating.affix-bottom {
  position: absolute;
  visibility: hidden;
}

.delivery-area {
  overflow: hidden;
  iframe {
    border: 0;
    width: 100%;
    height: 480px;
    margin-top: -50px;
  }
}