@import "../../shared/components/flex/sass/flex";

.kpr-fixed-panel-container {
    @include display-flex();
    @include flex-direction-vertical();
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    margin-left: -15px;
    margin-right: -15px;

    .kpr-header-panel {
        @include display-flex();
        @include align-center();
        min-height: 60px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .kpr-body-panel {
        @include display-flex();
        @include flex(1, 0, 0);
        @include flex-direction-horizontal();
        min-height: 100%;
        max-height: 100%;

        .kpr-content-panel {
            @include display-flex();
            @include flex(1, 0, 0);
            overflow: auto;
        }

        .kpr-sidebar-panel {
            overflow-y: auto;
            min-width: 30%;
            max-width: 30%;
            max-height: 100%;
            min-height: 100%;
            background-color: #ebebed;

            .kpr-sidebar-header {
                padding: 7px 7px 5px 7px;
            }

            .ibox {
                margin-bottom: 0;
            }
        }
    }
}

.ibox.fullscreen .kpr-sidebar .collapse-link {
    display: block !important;
}
