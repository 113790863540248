/*------------------------------------------------------------------
   Import the base
-------------------------------------------------------------------*/
@import url("default.css");

/*------------------------------------------------------------------
   Border Color
-------------------------------------------------------------------*/
/* #om_menu { border:1px solid #abbd26 } */

/*------------------------------------------------------------------
   Background Color
-------------------------------------------------------------------*/
#om_menu, #om_menu dt, #om_menu dd.price { background-color:#fff; }

/*------------------------------------------------------------------
   Font colors
-------------------------------------------------------------------*/
#om_menu .menu_content h2 { color:#131618; }
#om_menu dt { color:#4D595F; }
#om_menu dd.price { color:#406F0D; }
#om_menu dd.description { color:#7D898F; }


/*-------------------------------------------------------------------
    KEEPER Overrides - mgupta 2021/06/02
--------------------------------------------------------------------*/
#om_menu dt {
    font-weight: bold;
    font-size: 14px;
}

#om_menu .menu_name {
    font-weight: normal;
    font-size: 2em;
    text-transform: uppercase;
}

#om_menu .menu_content h2 {
    font-size:1.5em;
    font-weight: bold;
}

#om_menu dd.sizes {
    padding-top: 5px;
}

#om_menu dd.sizes span {
    margin-bottom: 8px;
    margin-right: 15px;
    background-color: #fafafa;
    border: 1px solid #eee;
    padding: 4px 6px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    line-height: 1em
}

#openmenu small {
    display: none;
}

/*#om_menu dt { color: #ffc621; }*/