$timeline-cell-border:            rgb(231, 234, 236);
$timeline-cell-border-lt:         lighten($timeline-cell-border, 5%);
$timeline-cell-width:             1.5rem;
$timeline-cell-height:            3rem;
$timeline-cell-padding:           .6rem;
$timeline-label-width:            6rem;
$timeline-body-max-height:        300px;
$timeline-body-max-width:         100%;
$timeline-allocated-color:        #ff5c77;
$timeline-infeasible-color:       darken($timeline-cell-border, 20%);

.kpr-timeline-container {

  width: $timeline-body-max-width;
  white-space: nowrap;

  .kpr-empty, .kpr-label {
    min-width: $timeline-label-width;
    overflow: hidden;
    td {
      min-width: $timeline-label-width;
      background-color: rgb(245, 245, 245);
    }
  }

  .kpr-header, .kpr-timeline-body {
    overflow: hidden;
    width: $timeline-body-max-width;
  }

  .kpr-header {
    td:nth-child(1) {
      border-left: 1px solid $timeline-cell-border;
    }
    td {
      background-color: rgb(245, 245, 245);
      border-right: 1px solid $timeline-cell-border;
      text-align: left;
      vertical-align: middle;
    }
  }

  .kpr-label {
    text-align: center;
  }

  .kpr-timeline-body {
    overflow: auto;

    tr {
      td:nth-child(1) {
        border-left: 1px solid $timeline-cell-border;
      }
      td:nth-child(4n) {
        border-right: 1px solid $timeline-cell-border;
      }
      td {
        border-right: 1px solid $timeline-cell-border-lt;
        &:hover {
          background-color: darken($timeline-cell-border, 5);
        }
        &.kpr-allocated-slot {
          background-color: $timeline-allocated-color !important;
        }

        &.kpr-infeasible-slot {
          background-color: $timeline-infeasible-color !important;
        }
      }
    }
  }
}

table.kpr-timeline {
  border-collapse: collapse;

  tr {
    height: $timeline-cell-height;

    td {
      padding: 0;
      min-width: $timeline-cell-width;
      border-top: 1px solid $timeline-cell-border;
      &.kpr-extra-wide {
        min-width: $timeline-cell-width * 4;
      }
      &.kpr-alloted {
        background-color: red;
      }
    }
  }
}