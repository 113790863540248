/*------------------------------------------------------------------
	DO NOT ALTER - Colors can be changed in the style.css file
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
	Coupon / Deals
-------------------------------------------------------------------*/
.deal { font-family:Helvetica,Arial,sans-serif; font-size:12px; color:#646464; line-height:1.5em; border:1px solid #ccc; background-color:#fff;padding:5px}
.deal_details { border:2px dashed #9ba940; padding:5px }
#deal_image {padding:0 10px 10px 10px; float:left;}
#deal_local {font-size:.9em; font-weight:bold; color:#999; padding-top:0}
.deal h3 {color:#64649e; font-size:1.4em; margin:0; padding:0}
.deal p {font-family:Verdana,Helvetica,Arial,sans-serif; padding:0; margin:0}
#deal_validity { clear:both; color:#849037; font-size:.9em}
#deal_disclaimer {clear:both; font-size:.9em; font-style:italic; padding-top:2px;}
#action_bar { float:right; width:90px; padding:3px; text-align:center; background-color:#fafafa; border:1px solid #ececec }
#action_bar a { font-size:.9em; font-weight:bold; color:#777 }
#action_bar img { vertical-align:middle }

/*------------------------------------------------------------------
	Coupon / Deals - Compact View
-------------------------------------------------------------------*/
.deal_compact {font-family:Helvetica,Arial,sans-serif; text-align:center;font-size:14px; padding:5px;margin:0 0 10px 0; border:2px dashed #9ba940 ;background-color:#fff; -moz-border-radius:8px; -webkit-border-radius:8px; -khtml-border-radius:8px; border-radius:8px;}
.deal_compact a {display:block; color:#64649e; font-weight:bold; text-decoration:none}
.deal_compact img {vertical-align:middle;padding-right:5px;}

.center_deal { margin:0 auto 10px auto; }


/*------------------------------------------------------------------
	OpenMenu
-------------------------------------------------------------------*/
#venue_logo {margin-top:20px;display: block; text-align: center;margin:0 auto;}

#openmenu { margin-bottom:10px; font-family: Arial, Verdana, Helvetica, sans-serif;}
#openmenu .clear { clear:both; }
#openmenu small { font-size:.9em }
#openmenu .sm_norm { font-weight:normal;font-size:.8em }
#om_restaurant { margin-bottom:20px; font-size:1em;}
#om_restaurant #rest_name { font-weight:bold; font-size:1.1em; }
#om_restaurant #locationmap { width:349px; height:300px }
#om_restaurant #location-map {float:right; border:1px solid #a78112; padding:3px; margin-left:5px; background:#f6de98 no-repeat url('images/map_error.png') center center}
#om_restaurant p { margin-bottom:5px; }
#om_restaurant #details { margin-top:15px;} 
#om_menu { background-color:#fff; font-size:.9em; padding:5px; }
#om_menu .full-menu {padding:5px;}
#om_menu .left-menu {float:left; width:48%; padding:0 }
#om_menu .right-menu {float:right; width:46%; padding:0 0 0 15px; margin:0 0 0 20px; border-left:1px solid #8FACBF }
#om_menu .menu_content { padding:5px; margin-bottom:5px}
#om_menu .menu_content h2 { font-size:1.2em; margin-top:0; margin-bottom:8px; }
#om_menu #details {margin-top:15px;}
#om_menu .menu_note { font-weight:normal;font-size:.9em;font-style:italic;text-align:center;margin:-8px 0 9px 0 }
#om_menu .group_note { font-weight:normal;font-size:.9em;font-style:italic;text-align:center;margin:5px 0 -10px 0 }
#om_menu dd { margin:0 }
#om_menu dl { display:block; overflow:auto; margin: 0 0 0.7em 10px; background: url('images/menu_dot.gif') repeat-x 0 14px }
#om_menu dt, #openmenu dd.price { font-size:1em; font-weight:bold }
#om_menu dt.pepper_1 { background: #fff url('images/peppers-1.png') no-repeat 0 0; padding-left:22px}
#om_menu dt.pepper_2 { background: #fff url('images/peppers-2.png') no-repeat 0 0; padding-left:32px}
#om_menu dt.pepper_3 { background: #fff url('images/peppers-3.png') no-repeat 0 0; padding-left:48px}
#om_menu dt.pepper_4 { background: #fff url('images/peppers-4.png') no-repeat 0 0; padding-left:60px}
#om_menu dt.pepper_5 { background: #fff url('images/peppers-5.png') no-repeat 0 0; padding-left:74px}
#om_menu dt { background-color:#fff; color:#4D595F; font-size:16px; font-weight:normal; float:left; padding-right:5px }
#om_menu dd.price { color:#406F0D; background-color:#fff; font-weight:normal; font-size:15px; float:right; padding-left:5px; }
#om_menu dl .mi_thumb { float:left; padding-right:5px }
#om_menu dd.description { color:#7D898F; font-size:14px; font-style:italic; font-weight:normal; line-height:20px; float:left; width:100%; padding: 3px 0 0 0; }
#om_menu dd.sizes { font-size:1em; font-weight:normal; float:left; width:100%; text-align:left }
#om_menu dd.sizes span { padding-right:15px }
#om_menu dd.allergy { font-size:.9em; font-weight:normal; float:left; width:100%; text-align:left; color:#4d595f }
#om_menu dd.allergy span { padding-right:10px }
#om_menu dd.item_options { font-size:1.1em; font-weight:normal; float:left; width:100%; text-align:center; }
#om_menu dd.item_options div { padding-bottom:3px;}
#om_menu span.item_tag { color:#f1f1f1; font-size:11px; font-weight:bold; padding:1px 3px 0;position:relative; text-transform:uppercase; top:-2px; margin-right:5px }
#om_menu span.special { background:#579F09 }
#om_menu span.gluten_free { background:#09a1a1 }
#om_menu span.kosher, #om_menu span.halal { background:#c00bba }
#om_menu span.new { background:#EF1E0E }
#om_menu span.vegetarian, #om_menu span.vegan { background:#816554 }
#om_menu #stk {text-align:center;margin:15px 0 8px 0}
#om_menu #stk span.item_tag {margin:0 3px 0 5px}
#om_menu .menu_name { font-weight:bold; font-size:1.3em; margin-bottom:10px; background-color:#ececec; padding:5px; }
#om_menu .goptions { font-size:.9em; text-align:center; padding:5px 0; }
#om_menu .goptions-title { font-size:1em; font-weight:bold; padding-bottom:3px }
#om_menu .goptions-desc { font-weight:normal;font-size:.9em;color:#777}
#om_menu span.separator { display:block; height:26px; margin:5px 0; }
#om_menu span.big { background:url('images/menu_big_separator.png') no-repeat top center }
#om_menu span.small { background:url('images/menu_sm_separator.png') no-repeat top center }

/*------------------------------------------------------------------
	IE7 Hack
-------------------------------------------------------------------*/
*:first-child+html #om_menu dl {overflow:visible;}