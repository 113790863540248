.menu-page {

  $section-title-font: Futura, "Open Sans", sans-serif;
  $section-title-font-size: 1.5em;

  $dish-name-font: "DIN Alternate", "Open Sans", sans-serif;
  $dish-name-font-weight: 600;

  $menu-desc-font: Baskerville, "Times New Roman", serif;
  $menu-desc-font-size: 1.4em;

  $menu-ethnic-font: $menu-desc-font;
  $menu-ethnic-font-size: 1.3em;

  $cell-menu-curry-font: $menu-desc-font;
  $cell-menu-desc-font-size: 1.3em;

  html {
    font-size: 14px;
  }

  body, div, dl, dt, dd, h1, h2, h3, h4, h5, h6, p, pre, code, blockquote {
    margin: 0;
    padding: 0;
    border-width: 0;
  }
  body {
    -epub-hyphens: auto;
  }
  img.Basic-Graphics-Frame {
    border-color: #000000;
    border-width: 0px;
    border-style: solid;
  }
  div.Basic-Text-Frame {
    border-style: solid;
  }
  div.section-icon, img.section-icon {
    border-style: solid;
  }
  div.vcentered-text-frame {
    padding-top: 72px;
    border-style: solid;
  }
  table.sorse-border {
    border-collapse: collapse;
    border-color: $brand-color;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 6px;
    margin-top: 6px;
  }
  td.sorse-border {
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #000000;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #000000;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #000000;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #000000;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    vertical-align: top;
  }
  table.without-borders {
    border-collapse: collapse;
    border-color: #000000;
    border-style: none;
    border-width: 0px;
    margin-bottom: -4px;
    margin-top: 4px;
  }
  td.without-borders {
    border-left-width: 0px;
    border-left-style: solid;
    border-left-color: #000000;
    border-top-width: 0;
    border-top-style: solid;
    border-top-color: #000000;
    border-right-width: 0;
    border-right-style: solid;
    border-right-color: #000000;
    border-bottom-width: 0;
    border-bottom-style: solid;
    border-bottom-color: #000000;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    vertical-align: top;
  }
  td.sorse-cells_v-cell-group_body_left-most {
    border-bottom-color: $brand-color;
    border-bottom-width: 0px;
    border-left-style: solid;
    border-left-width: 0px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: $brand-color;
    border-top-width: 0px;
    padding-bottom: 3px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
    vertical-align: top;
  }
  td.sorse-cells_v-cell-group_body_middle-cell {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-color: $brand-color;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 0px;
    padding-bottom: 3px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
    vertical-align: top;
  }
  td.sorse-cells_v-cell-group_body_right-most {
    border-bottom-color: #93730a;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-color: $brand-color;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-width: 0px;
    border-top-color: #93730a;
    border-top-style: solid;
    border-top-width: 0px;
    padding-bottom: 3px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
    vertical-align: top;
  }
  td.sorse-cells_v-cell-group_footer_left-most {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-width: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    vertical-align: middle;
  }
  td.sorse-cells_v-cell-group_footer_middle {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: $brand-color;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-width: 0px;
    padding-bottom: 6px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 0px;
    vertical-align: middle;
  }
  td.sorse-cells_v-cell-group_footer_right-most {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: $brand-color;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 0px;
    border-top-width: 0px;
    padding-bottom: 6px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 0px;
    vertical-align: middle;
  }
  td.sorse-cells_v-cell-group_header_left-most- {
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 1px;
    padding-bottom: 6px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 6px;
    vertical-align: top;
  }
  td.sorse-cells_v-cell-group_header_middle- {
    border-bottom-width: 0px;
    border-left-color: $brand-color;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 1px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    vertical-align: top;
  }
  td.sorse-cells_v-cell-group_header_right-most {
    border-bottom-width: 0px;
    border-left-color: $brand-color;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-width: 0px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 1px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 6px;
    vertical-align: top;
  }
  td.sorse-cells_v-cell-single_leftmost-cell {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 1px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    vertical-align: middle;
  }
  td.sorse-cells_v-cell-single_rightmost-cell {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: $brand-color;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 0px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 1px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    vertical-align: middle;
  }
  td.sorse-cells_v-cell-single_span-all-cols {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-style: solid;
    border-left-width: 0px;
    border-right-style: solid;
    border-right-width: 0px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 1px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    vertical-align: middle;
  }
  p.cell-menu_curry {
    -epub-hyphens: none;
    color: #ffc621;
    font-family: $cell-menu-curry-font;
    font-size: $menu-desc-font-size;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    line-height: 1.263;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 6px;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  p.cell-menu_description {
    color: #6d6e71;
    font-family: $menu-desc-font;
    font-size: $cell-menu-desc-font-size;
    font-style: italic;
    font-variant: normal;
    font-weight: normal;
    line-height: 1.143;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: avoid;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  p.cell-menu_dish {
    color: #ffc621;
    font-family: $section-title-font;
    font-size: 1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 6px;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: uppercase;
    widows: 1;
  }
  p.cell-menu_price {
    color: #000000;
    font-family: Georgia, serif;
    font-size: 1em;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1.333;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  p.menu_description {
    color: #6d6e71;
    font-family: $menu-desc-font;
    font-size: $menu-desc-font-size;
    font-style: italic;
    font-variant: normal;
    font-weight: normal;
    line-height: 1.143;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: avoid;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: lowercase;
    widows: 1;
  }
  p.menu_dish-name {
    color: #000000;
    font-family: $dish-name-font;
    font-size: 1.2em;
    font-style: normal;
    font-variant: normal;
    font-weight: $dish-name-font-weight;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.2em;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  p.menu_ethnic-name {
    color: #ffc621;
    font-family: $menu-ethnic-font;
    font-size: $menu-ethnic-font-size;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    line-height: 1.2;
    margin: 1.2em 0 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: avoid;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: lowercase;
    widows: 1;
  }
  p.menu_fine-print {
    color: #6d6e71;
    font-family: $menu-desc-font;
    font-size: 1em;
    font-style: italic;
    font-variant: normal;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: avoid;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  p.menu_section-title {
    color: #ffc621;
    font-family: $section-title-font;
    font-size: $section-title-font-size;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 18px;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: uppercase;
    widows: 1;
  }
  p.menu_section-title-no-icon {
    color: #ffc621;
    font-family: $section-title-font;
    font-size: $section-title-font-size;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 11px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 18px;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: uppercase;
    widows: 1;
  }
  p.menu_steps {
    color: #000000;
    font-family: "Open Sans Semibold", sans-serif;
    font-size: 1.2em;
    font-style: normal;
    font-variant: small-caps;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 6px;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  span.fontawesome {
    color: #ffc621;
    font-family: FontAwesome, sans-serif;
    font-size: 1.2em;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-transform: none;
  }
  span.slashed-price {
    color: #000000;
    font-family: Georgia, serif;
    font-size: 1em;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-transform: none;
  }
  span.slashed-price::before {
    content: " ";
  }
  span.price {
    color: darken($dark-gray, 5%);
    font-family: Georgia, serif;
    font-size: 1em;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-transform: none;
    text-decoration: line-through;
  }
  span.quantity {
    color: #000000;
    font-family: Georgia, serif;
    font-size: 1em;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-transform: none;
  }
  span.royal {
    font-family: "Edwardian Script ITC", sans-serif;
    font-size: 2em;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-transform: uppercase;
  }
  table.TableOverride-1 {
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
  }
  table.TableOverride-2 {
    border-collapse: collapse;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
  }
  td.CellOverride-1 {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    vertical-align: top;
  }
  td.CellOverride-2 {
    border-left-color: #ffc621;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: #ffc621;
    border-right-style: solid;
    border-right-width: 1px;
    padding-bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0px;
  }
  td.CellOverride-3 {
    padding-bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0px;
  }
  td.CellOverride-4 {
    vertical-align: middle;
  }
  td.CellOverride-5 {
    vertical-align: bottom;
  }
  td.CellOverride-6 {
    border-left-color: #ffc621;
    border-left-width: 1px;
    border-right-color: #ffc621;
    border-right-width: 1px;
    padding-bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0px;
  }
  td.CellOverride-7 {
    border-left-color: #ffc621;
    border-left-width: 1px;
    border-right-color: #ffc621;
    border-right-width: 1px;
    padding-bottom: 0px;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 0px;
  }
  span.CharOverride-1 {
    color: #6d6e71;
    font-style: italic;
    font-weight: normal;
  }
  span.CharOverride-2 {
    font-family: Georgia, serif;
    font-size: 8px;
    font-style: normal;
    font-weight: normal;
  }
  span.CharOverride-3 {
    font-size: 12px;
  }
  p._idGenParaOverride-1 {
    page-break-after: always;
  }
  td._idGenCellOverride-1 {
    border-left-style: solid;
    border-left-width: 0px;
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 0px;
    vertical-align: middle;
  }
  td._idGenCellOverride-2 {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  td._idGenCellOverride-3 {
    border-left-style: solid;
    border-left-width: 0px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    vertical-align: middle;
  }
  td._idGenCellOverride-4 {
    border-right-color: $brand-color;
    border-right-style: solid;
    border-right-width: 0px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    vertical-align: middle;
  }
  td._idGenCellOverride-5 {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 0px;
  }
  td._idGenCellOverride-6 {
    border-bottom-color: #93730a;
    border-bottom-style: solid;
    border-bottom-width: 0px;
  }
  td._idGenCellOverride-7 {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-style: solid;
    border-left-width: 0px;
    border-top-style: solid;
    border-top-width: 0px;
  }
  td._idGenCellOverride-8 {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 0px;
  }
  td._idGenCellOverride-9 {
    border-bottom-color: #93730a;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-right-style: solid;
    border-right-width: 0px;
    border-top-color: #93730a;
    border-top-style: solid;
    border-top-width: 0px;
  }
  td._idGenCellOverride-10 {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-style: solid;
    border-left-width: 0px;
    border-top-color: $brand-color;
    border-top-style: solid;
    border-top-width: 0px;
  }
  td._idGenCellOverride-11 {
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    vertical-align: middle;
  }
  td._idGenCellOverride-12 {
    border-bottom-color: $brand-color;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-color: $brand-color;
    border-left-style: solid;
    border-left-width: 1px;
  }
  td._idGenCellOverride-13 {
    border-bottom-color: #93730a;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-right-style: solid;
    border-right-width: 0px;
    border-top-style: solid;
    border-top-width: 0px;
  }
  td._idGenCellOverride-14 {
    border-top-color: #93730a;
    border-top-style: solid;
    border-top-width: 0px;
  }
  col._idGenTableRowColumn-1 {
    width: 133px;
  }
  tr._idGenTableRowColumn-2 {
    min-height: 50px;
  }
  tr._idGenTableRowColumn-3 {
    min-height: 54px;
  }
  col._idGenTableRowColumn-4 {
    width: 203px;
  }
  tr._idGenTableRowColumn-5 {
    min-height: 49px;
  }
  tr._idGenTableRowColumn-6 {
    min-height: 180px;
  }
  col._idGenTableRowColumn-7 {
    width: 103px;
  }
  tr._idGenTableRowColumn-8 {
    min-height: 30px;
  }
  tr._idGenTableRowColumn-9 {
    min-height: 42px;
  }
  col._idGenTableRowColumn-10 {
    width: 80px;
  }
  col._idGenTableRowColumn-11 {
    width: 43px;
  }
  col._idGenTableRowColumn-12 {
    width: 38px;
  }
  col._idGenTableRowColumn-13 {
    width: 79px;
  }
  tr._idGenTableRowColumn-14 {
    min-height: 24px;
  }
  tr._idGenTableRowColumn-15 {
    min-height: 84px;
  }
  tr._idGenTableRowColumn-16 {
    min-height: 18px;
  }
  tr._idGenTableRowColumn-17 {
    min-height: 72px;
  }
  col._idGenTableRowColumn-18 {
    width: 37px;
  }
  tr._idGenTableRowColumn-19 {
    min-height: 90px;
  }
  tr._idGenTableRowColumn-20 {
    min-height: 78px;
  }
  col._idGenTableRowColumn-21 {
    width: 91px;
  }
  col._idGenTableRowColumn-22 {
    width: 46px;
  }
  col._idGenTableRowColumn-23 {
    width: 45px;
  }
  col._idGenTableRowColumn-24 {
    width: 92px;
  }
  tr._idGenTableRowColumn-25 {
    height: 30px;
  }
  tr._idGenTableRowColumn-26 {
    min-height: 48px;
  }
  col._idGenTableRowColumn-27 {
    width: 227px;
  }
  tr._idGenTableRowColumn-28 {
    min-height: 168px;
  }
  tr._idGenTableRowColumn-29 {
    min-height: 36px;
  }
  col._idGenTableRowColumn-30 {
    width: 238px;
  }
  tr._idGenTableRowColumn-31 {
    min-height: 51px;
  }
  tr._idGenTableRowColumn-32 {
    min-height: 117px;
  }
  #_idContainer000 {
    display: inline-block;
    height: 43px;
    width: 40px;
  }
  #_idContainer001 {
    display: inline-block;
    height: 29px;
    width: 133px;
  }
  #_idContainer002 {
    display: inline-block;
    height: 27px;
    width: 133px;
  }
  #_idContainer004 {
    display: inline-block;
    height: 29px;
    width: 29px;
  }
  #_idContainer005 {
    display: inline-block;
    height: 31px;
    width: 29px;
  }
  #_idContainer007 {
    display: inline-block;
    height: 42px;
    width: 43px;
  }
  #_idContainer009 {
    display: inline-block;
    height: 29px;
    width: 203px;
  }
  #_idContainer010 {
    display: inline-block;
    height: 39px;
    width: 51px;
  }
  #_idContainer011 {
    display: inline-block;
    height: 27px;
    width: 203px;
  }
  #_idContainer012 {
    display: inline-block;
    height: 40px;
    width: 54px;
  }
  #_idContainer014 {
    display: inline-block;
    height: 39px;
    width: 33px;
  }
  #_idContainer016 {
    display: inline-block;
    height: 32px;
    width: 40px;
  }
  #_idContainer020 {
    display: inline-block;
    height: 39px;
    width: 40px;
  }
  #_idContainer024 {
    display: inline-block;
    height: 26px;
    width: 43px;
  }
  #_idContainer028 {
    display: inline-block;
    height: 40px;
    width: 33px;
  }
  #_idContainer029 {
    display: inline-block;
    height: 39px;
    width: 43px;
  }
  #_idContainer032 {
    display: inline-block;
    height: 37px;
    width: 54px;
  }
  #_idContainer034 {
    display: inline-block;
    height: 39px;
    width: 29px;
  }
  #_idContainer037 {
    display: inline-block;
    height: 39px;
    width: 70px;
  }
  img._idGenObjectAttribute-1 {
    height: 100.00%;
    min-width: 100%;
    width: 100.00%;
  }
  img._idGenObjectAttribute-2 {
    height: 18px;
    width: 18px;
  }
  img._idGenObjectAttribute-3 {
    height: 33px;
    width: 228px;
  }
  img._idGenObjectAttribute-4 {
    height: 35px;
    width: 240px;
  }
  div._idGenObjectLayout-1 {
    text-align: center;
  }

  // mgupta: customized styles
  table.width-100 {
    width: 100%;
  }

  table.width-80 {
    width: 80%;
  }

  table.width-60 {
    width: 80%;
  }
}