.drawer-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  top: 100%;
  transform: translateY(-100%);
  z-index: 9998;
  padding: 20px;

  .drawer-container {
    height: 100%;
    width: 100%;
    padding: 15px;
  }

  .drawer-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .drawer-body {
    margin: 20px 0;
  }

  .drawer-default-button {
    float: right;
  }
}

.slide-up-drawer-enter {
  transform: translateY(0%);
}

.slide-up-drawer-enter-active {
  transition: transform .3s ease-out;
}

.slide-up-drawer-enter-to {
  transform: translateY(-100%);
}

.slide-up-drawer-leave {
  transform: translateY(-100%);
}

.slide-up-drawer-leave-to {
  transform: translateY(0%);
}

.slide-up-drawer-leave-active {
  transition: transform .3s ease-in;
}

.out-left-enter-active,
.out-left-leave-active,
.out-right-enter-active,
.out-right-leave-active {
  transition: all 5s ease;
}

.out-left-leave-to,
.out-right-enter {
  position: absolute;
  transform: translateX(-100%);
  opacity: 0;
}

.out-left-enter,
.out-right-leave-to {
  position: absolute;
  transform: translateX(100%);
  opacity: 0;
}

.kpr-sliding-tabs {
  height: 100%;
  width: 100%;
  @include display-flex();
  @include pack-start();
  @include flex-direction-vertical();

  .kpr-header {

  }

  .kpr-body {
    @include display-flex();
    @include flex(1, 0, 0);
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    overflow-y: auto;

    .kpr-sliding-tab {
      width: 100%;
    }
  }

  .kpr-footer {
    @include display-flex();
    @include pack-end();
    border-top: 1px solid #e7eaec;
    padding-top: 20px;
  }

}